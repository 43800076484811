import React from 'react';
import styled, { css } from 'styled-components';

// vertical-align: top;は、横線の高さを決めた関係
const AtagPrimary = styled.a`
  cursor: pointer;
  color: #004a87;
  font-size: 14px;
  line-height: 1.6;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    color: #007ce2;
    text-decoration: underline;
  }
`;

export default AtagPrimary;
