import React, { useState, useCallback, useEffect } from 'react';
import Head from 'next/head';
import Router from 'next/router';

const CustomizeHead = ({ title }) => {
  useEffect(() => {
    Router.events.on('routeChangeComplete', () => {
      window.scrollTo(0, 0);
    });
  });

  return (
    <Head>
      <title>{title} | 大和綾瀬歯科医師会 理事サイト</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <link rel="icon" href="/favicon.svg" />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      <link rel="stylesheet" href="https://unpkg.com/ress/dist/ress.min.css" />
    </Head>
  );
};

export default CustomizeHead;
