import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AtagPrimary } from '@/components/atoms/Atag';
import { useAuthContext } from '@/context/AuthContext';

const Header = React.memo(({ isButton }) => {
  const router = useRouter();
  const { user } = useAuthContext();

  return (
    <React.Fragment>
      <header>
        <HeaderInner>
          <div css="width:30%;max-width:330px;min-width:230px;">
            <Link href="/" passHref>
              <LogoImg src="/images/logo.png" alt="" css="cursor:pointer;" />
            </Link>
          </div>
          {user && (
            <div css="font-size: 12px;font-weight: bold;display: flex;align-items: baseline;margin-left: auto;">
              <AtagPrimary href="/logout">ログアウト</AtagPrimary>
              <p css="margin-left:8px;">{user.name} 様</p>
            </div>
          )}
        </HeaderInner>

        <HeaderNav>
          <HeaderNavInner>
            <NavUl>
              <NavLi>
                <Link href="/categories/rijikai">
                  <NavA>理事会</NavA>
                </Link>
                <Line />
              </NavLi>
              <NavLi>
                <Link href="/categories/joumukai">
                  <NavA>常務会</NavA>
                </Link>
                <Line />
              </NavLi>
              <NavLi>
                <Link href="/categories/teijisoukai">
                  <NavA>定時総会</NavA>
                </Link>
                <Line />
              </NavLi>
              <NavLi>
                <Link href="/categories/oshirase">
                  <NavA>お知らせ</NavA>
                </Link>
              </NavLi>
            </NavUl>
          </HeaderNavInner>
        </HeaderNav>
      </header>
    </React.Fragment>
  );
});

const HeaderInner = styled.div`
  width: 90%;
  max-width: 1150px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 140px;
`;
const LogoImg = styled.img`
  width: 100%;
  height: auto;
`;

/* headerナビゲーション */
const HeaderNav = styled.div`
  background: #35b401;
`;
const HeaderNavInner = styled.nav`
  width: 90%;
  max-width: 1150px;
  margin: 0 auto;
`;
const NavUl = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 64px;
`;
const NavLi = styled.li`
  width: 25%;
  line-height: 5rem;
  text-align: center;
  position: relative;
`;

const NavA = styled.a`
  display: block;
  width: 100%;
  color: #fff;
  //   border-radius: 10px;
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: bold;
  position: relative;
  &:hover {
    background-color: #628453;
    cursor: pointer;
  }
`;
const Line = styled.span`
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
  width: 1px;
  height: 1rem;
  background-color: #fff;
`;
export default Header;
