import { Loading } from '@/components/shared';
import firebase, { auth, db } from '@/firebase/clientApp';
import Router, { useRouter } from 'next/router';
import { createContext, useEffect, useState, useContext } from 'react';

const AuthContext = createContext({ user: undefined });

export const AuthProvider = ({ children }) => {
  const router = useRouter();
  const [user, setUser] = useState(undefined);
  const [loadingUser, setLoadingUser] = useState(true); // Helpful, to update the UI accordingly.
  const [errorUser, setErrorUser] = useState(false);

  useEffect(() => {
    const unsubscriber = auth.onAuthStateChanged(async (user) => {
      // ログイン状態が変化すると呼ばれる
      try {
        if (!user) {
          setUser(null);
        } else {
          // firebaseからデータを取得
          const doc = await db.collection('directors').doc(user.uid).get();
          // 取得したデータの格納
          const data = doc.data();
          if (!data || data.active === false) {
            setUser(null); // ユーザーがない
          } else {
            const result = {
              uid: doc.id,
              name: data.name,
              level: data.level,
            };
            setUser(result);
          }
        }
      } catch (error) {
        console.error(error);
        setErrorUser(true);
      } finally {
        setLoadingUser(false);
      }
      //
    });
    // Unsubscribe auth listener on unmount
    return () => unsubscriber();
  }, []);

  if (loadingUser) return <Loading />;

  if (errorUser) {
    router.push('/systemerror');
  }

  return (
    <AuthContext.Provider value={{ user, setUser, loadingUser, errorUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
